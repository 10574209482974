<template>
  <LDefault>
    <OTitle>Block number</OTitle>
    <CFormBlockNumber @formSuccessSubmit="handleFormSuccessSubmit" @formSubmit="initBlockNumber"></CFormBlockNumber>
    <CFormVerificationCode v-if="to" :to="to" @formSuccessSendCode="handleFormSuccessSendCode"></CFormVerificationCode>
    <div>
      <p class="o-help_block_number">{{ this.successMsg }}</p>
    </div>
    <CAdBitGiftCard v-if="!isLoggedIn"/>
  </LDefault>
</template>

<script>
import CFormBlockNumber from '../forms/CFormBlockNumber'
import CFormVerificationCode from '../forms/CFormVerificationCode'
import LDefault from '../layouts/LDefault'
import OTitle from '../objects/OTitle'
import CAdBitGiftCard from '@/components/CAdBitGiftCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PageBlockNumber',
  components: { CAdBitGiftCard, OTitle, LDefault, CFormBlockNumber, CFormVerificationCode },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Block Number',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Block Number'
      },
      {
        name: 'keywords',
        content: 'myphonerobot block number'
      }
    ]
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  data: () => ({
    successMsg: null,
    to: null
  }),
  methods: {
    initBlockNumber () {
      this.successMsg = null
    },
    handleFormSuccessSubmit (phone) {
      this.to = phone
    },
    handleFormSuccessSendCode (msg) {
      this.to = null
      this.successMsg = msg
    }
  }
}
</script>

<style lang="scss">
.o-help_block_number {
  color: $primary;
}
</style>
