<template>
  <l-default>
    <OTitle>Recording Logs</OTitle>
    <CShareThisInline/>
    <CCalls/>
    <CAdBitGiftCard />
  </l-default>
</template>

<script>
import LDefault from '../layouts/LDefault'
import OTitle from '../objects/OTitle'
import CCalls from '../components/CCalls'
import CShareThisInline from '../components/CShareThisInline'
import CAdBitGiftCard from '../components/CAdBitGiftCard'

export default {
  name: 'PageRecordingLogs',
  components: { OTitle, LDefault, CCalls, CShareThisInline, CAdBitGiftCard }
}
</script>
