<template>
  <l-default>

    <OTitle>Discounts & Free</OTitle>

    <CSocialLogin @login="onSocialLogin" v-if="!isLoggedIn"></CSocialLogin>

    <div>
      <CShareThisInline  v-if="isLoggedIn" />

      <h2 class="c_free_credits">EMAIL</h2>

      IMPORTANT: Make sure you can receive emails from <em>info@myphonerobot.com</em>. Check your <em>spam folder</em>,
      check your <em>promotions folder</em> and <em>mark</em> our emails as <em>important</em> so you can receive them
      freely. Instruct your email client to <em>whitelist info@myphonerobot.com</em>. We send emails with promo codes
      with free credits and discounts on a weekly basis. Free credits promo codes will just add some free credits to
      your balance for free, those are usually <em>valid for 2 days</em> since email is received. Discount promo codes
      will add more credits with every purchase, those are much more effective and they are usually <em>valid for 2 days</em>
      since email is received. You can unsubscribe at any time via the link at the bottom of every email.
    </div>
    <br/>
    <o-j-l-links v-if="!isLoggedIn"></o-j-l-links>
    <CAdBitGiftCard/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters, mapMutations } from 'vuex'
import CShareThisInline from '@/components/CShareThisInline.vue'
import OJLLinks from '@/objects/OJLLinks.vue'
import CSocialLogin from '@/components/CSocialLogin.vue'
import CAdBitGiftCard from '@/components/CAdBitGiftCard.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageFreeCredits',
  components: { CAdBitGiftCard, CSocialLogin, OJLLinks, CShareThisInline, OTitle, LDefault },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Free Credits',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Free Credits'
      },
      {
        name: 'keywords',
        content: 'myphonerobot free credits'
      }
    ]
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapMutations(['setAuth', 'clearPhones']),
    afterLogin () {
      this.clearPhones()
    },
    submit () {
      this.$callApi(api => api.login(this.form))
    },
    onSocialLogin ({ social, credential }) {
      if (social === 'google') {
        this.onGoogleLogin(credential)
      } else if (social === 'apple') {
        this.onAppleLogin(credential)
      }
    },
    async onGoogleLogin (token) {
      const api = await getApi()
      const result = await api.googleLogin({ token })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    },
    async onAppleLogin (code) {
      const api = await getApi()
      const result = await api.appleLogin({ code })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    }
  }
}
</script>

<style lang="scss">

.c_free_credits {
  color: $primary;
}

</style>
