<template>
  <l-default>
    <OTitle>Contact us</OTitle>
    <CShareThisInline/>
    <c-form-feedback-form
      v-if="!successMsg"
      @success="successMsg = $event.message"
    ></c-form-feedback-form>
    <p v-if="successMsg">{{successMsg}}</p>
    <p class="clegal">
      <b>Legal address:</b> Triadisa District 2A, Emiliyan Stanev Street, Sofia, Bulgaria
    </p>
    <CAdBitGiftCard v-if="!isLoggedIn"/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import CFormFeedbackForm from '../../forms/CFormFeedback'
import OTitle from '../../objects/OTitle'
import CShareThisInline from '../../components/CShareThisInline'
import CAdBitGiftCard from '@/components/CAdBitGiftCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PageFeedback',
  components: { CAdBitGiftCard, OTitle, CFormFeedbackForm, LDefault, CShareThisInline },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Contact us',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Contact us'
      },
      {
        name: 'keywords',
        content: 'myphonerobot contact us'
      }
    ]
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  data: () => ({ successMsg: null })
}
</script>

<style lang="scss">
  .clegal {
    font-size: 0.75em;
  }
</style>
