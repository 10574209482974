<template>
  <l-default>
    <OTitle>Buy credits</OTitle>
    <CShareThisInline/>
    <OCRow class="u-mb-3">
      <div>{{ email }}</div>
      <div class="u-ml-auto">{{ credits }} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive">
      <div style="text-align:center">
        <em>ALL PURCHASE ARE NON-REFUNDABLE, FOR MORE DETAILS SEE OUR </em>
        <o-link :to="{name: 'refund'}" style="text-decoration: underline">Refund Policy</o-link>
      </div>
      <div class="c-grid">
        <div class="c-grid__title">Choose price point:</div>
        <CBuyButton v-for="pricepoint in pricepoints"
                    v-bind:key="pricepoint.credits"
                    :credits="pricepoint.credits"
                    :bonus_credits="pricepoint.bonus_credits"
                    :cost="pricepoint.cost"
                    :always="true"
                    :isLink="true"
                    class="c-grid__item"></CBuyButton>
      </div>

      <CFormVoucher @success="onSuccess"></CFormVoucher>

    </div>
    <CAdBitGiftCard/>
  </l-default>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LDefault from '@/layouts/LDefault'
import CBuyButton from '../components/CBuyButton'
import OTitle from '../objects/OTitle'
import OCRow from '../objects/containers/OCRow'
import CVerifyEmail from '../components/CVerifyEmail'
import CFormVoucher from '../forms/CFormVoucher'
import OLink from '../objects/OLink'
import CAdBitGiftCard from '@/components/CAdBitGiftCard.vue'
import CShareThisInline from '@/components/CShareThisInline.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageCredits',
  components: {
    CShareThisInline,
    CAdBitGiftCard,
    OCRow,
    OTitle,
    CBuyButton,
    LDefault,
    CVerifyEmail,
    CFormVoucher,
    OLink
  },
  data: () => ({ selected: 0, loading: true, methods: null, pricepoints: [] }),
  computed: {
    ...mapGetters({ isLoggedIn: 'isLoggedIn', credits: 'getCredits', email: 'getEmail', isActive: 'isActive' })
  },
  methods: {
    ...mapActions(['fetchInfo']),
    async load () {
      const api = await getApi()
      const result = await api.getPricePoints({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      })
      if (result.error) {
        this.error = result.message
        return false
      }
      this.pricepoints = result.price_points
    },
    onSuccess () {
      this.fetchInfo()
      this.load()
    }
  },
  mounted () {
    this.load()
    this.fetchInfo()
  }
}
</script>

<style lang="scss">
.c-grid {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 1rem;
}

.c-grid__title {
  margin: 0;
  padding: 0.25em 0;
  text-transform: uppercase;
}

.c-grid__item {
  margin: 0 0 0.5rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-mb-3 {
  margin-bottom: 1.5rem;
}

</style>
