<template>
  <div class="acontainer">
    <div class="ad-bitgiftcard">
      <div class="atitle-bitgiftcard">
        <a href="https://bitgiftcard.com/us/product/virtual-visa-reward-6-month-90-days-to-redeem-only-us-1977" title="BITGIFTCARD">
          BITGIFTCARD</a>
      </div>
      <div class="alogo">
        <a href="https://bitgiftcard.com/us/product/virtual-visa-reward-6-month-90-days-to-redeem-only-us-1977" title="bitgiftcard.com">
            <img alt="Bitgiftcard" height="100" src="../assets/ads/BitcoinSign.svg">
        </a>
      </div>
      <div class="asubtitle-bitgiftcard">
        <a class="asubtitle-bitgiftcard" href="https://bitgiftcard.com/us/product/virtual-visa-reward-6-month-90-days-to-redeem-only-us-1977" title="BITGIFTCARD">
          Buy gift cards with bitcoin
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CAdBitGiftCard'
}
</script>

<style lang="scss">
.acontainer {
  margin-bottom: 15px;
  margin-top: 15px;
}

.ad-bitgiftcard {
  border: 1px solid #ffffff;
  max-width: 220px;
  margin: auto;
  background-image: url('../assets/ads/pattern.webp');
}

.alogo {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.acenter {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle-bitgiftcard {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.atitle-bitgiftcard a {
  color: #ffffff;
}

.asubtitle-bitgiftcard {
  text-align: center;
  margin: 0.5rem 0.2rem;
}

.asubtitle-bitgiftcard a {
  font-size: 0.9em;
  color: #ffffff;
}

</style>
