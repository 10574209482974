<template>
  <LDefault>
    <OTitle>Change password</OTitle>
    <CShareThisInline />
    <CFormChangePassword @success="onSuccess"></CFormChangePassword>
    <CAdBitGiftCard />
  </LDefault>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import CFormChangePassword from '@/forms/CFormChangePassword'
import OTitle from '../../objects/OTitle'
import CAdBitGiftCard from '../../components/CAdBitGiftCard'
import CShareThisInline from '@/components/CShareThisInline.vue'

export default {
  name: 'PagePassword',
  components: { CShareThisInline, CAdBitGiftCard, OTitle, LDefault, CFormChangePassword },
  methods: {
    onSuccess ({ message }) {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
