<template>
  <l-default>
    <OTitle>Join</OTitle>
    <CFormRegister @success="onSuccess" />
    <CAdBitGiftCard/>
  </l-default>
</template>

<script>
import CFormRegister from '../../forms/CFormRegister'
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapMutations } from 'vuex'
import CAdBitGiftCard from '@/components/CAdBitGiftCard.vue'

export default {
  name: 'PageRegister',
  components: { CAdBitGiftCard, OTitle, LDefault, CFormRegister },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Join',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Join'
      },
      {
        name: 'keywords',
        content: 'myphonerobot join'
      }
    ]
  },
  methods: {
    ...mapMutations({
      setGlobalMessage: 'setMessage'
    }),
    onSuccess ({ message }) {
      let page = 'home'
      if (this.$isMobile()) {
        page = 'spoof-call'
      }
      if (this.$store.getters.getPage === 'spoof-call') {
        page = 'spoof-call'
      }
      this.$router.push({ name: page })
      this.setGlobalMessage({ message: message })
    }
  }
}
</script>
